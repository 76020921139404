import React from 'react';
import ReactDOM from 'react-dom';
import MiniCart from './mini-cart';


document.addEventListener('turbolinks:load', function() {
    const cart = document.getElementById('miniCart')
    const link = JSON.parse(cart.getAttribute('link'))
    const count = JSON.parse(cart.getAttribute('count'))
    const price = JSON.parse(cart.getAttribute('price'))

    ReactDOM.render(
        <MiniCart link={link} count={count} price={price}/>, cart,
    )
});
