import React, {Fragment} from 'react'
import CartImg from "./images/mini-cart.png"

class MiniCart extends React.Component {
    render() {
        return(
            <a href={this.props.link}>{this.props.price}({this.props.count})
                <img src={CartImg} width="25"/>
            </a>
        )
    }
}

export default MiniCart
